import answerOptions from '../answer-option/index.vue'
import answerText from '../answer-text/index.vue'
import validationMixin from '@/mixins/validation'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'answer-home-work',
  mixins: [validationMixin],
  props: {
    item: {
      type: Object
    },
    testId: {
      type: Number
    },
    index: {
      type: Number
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  components: {
    answerOptions,
    answerText
  },
  computed: {
    ...mapGetters({
      currentItem: 'homeWorkResult/currentItem'
    }),
    userAnswers () {
      const filteredList = this.item.answers.data.filter(e => {
        return e.userAnswer.data.hasOwnProperty('id')
      })

      return filteredList.map(e => {
        return e.userAnswer.data
      })
    }
  },
  methods: {
    ...mapActions({
      accept: 'homeWorkResult/ACCEPT_QUESTION',
      decline: 'homeWorkResult/DECLINE_QUESTION'
    }),
    createRequestPayload (type) {
      const formData = new FormData()
      formData.append('homeWorkId', this.currentItem.id)
      formData.append('id', this.item.userQuestion.data.id)
      this.userAnswers.forEach((e, index) => {
        formData.append(`answers[${index}][id]`, e.id)
        formData.append(`answers[${index}][correct]`, type === 'accept' ? 1 : 0)
      })
      formData.append('_method', 'patch')
      return formData
    },
    changeResultQuestion (type) {
      this.isLoading = true
      if (type === 'accept') {
        this.accept(this.createRequestPayload(type)).then(response => {

        }).finally(() => {
          this.isLoading = false
        })
      } else {
        this.decline(this.createRequestPayload(type)).then(response => {

        }).finally(() => {
          this.isLoading = false
        })
      }
    }
  }
}
