import { mapActions, mapGetters, mapMutations } from 'vuex'
import hwHeader from '../../components/header/index.vue'
import hwFooter from '../../components/footer/index.vue'
import hwTemplate from '../../components/answer-home-work/index.vue'

export default {
  name: 'user-home-work-details',
  components: {
    hwHeader,
    hwFooter,
    hwTemplate
  },
  created() {
    if (this.$route.params.hasOwnProperty('userHomeWorkId')) {
      this.fetchItem({
        workType: 'homeWork',
        homeWorkId: this.$route.params.userHomeWorkId
      })
    }
  },
  computed: {
    ...mapGetters({
      currentItem: 'homeWorkResult/currentItem'
    })
  },
  methods: {
    ...mapActions({
      fetchItem: 'homeWorkResult/GET_LIST_ITEM'
    }),
    ...mapMutations({
      setItem: 'homeWorkResult/SET_ITEM'
    })
  },
  destroyed() {
    this.setItem(null)
  }
}
